import React, { useEffect } from 'react';
import C6 from '../../imgs/logo_C6.svg';

import { useSelector, useDispatch } from 'react-redux';

import './telaErro.css';

export default function TelaErro() {
	const telefone = useSelector(state => state.usuarioReducer.telefone);
	const errorMessage = useSelector(state => state.estadoReducer.errorMessage);
	const dispatch = useDispatch();

	useEffect(() => {
        dispatch({ type: 'SET_LOADING_PAGE', payload: false });
        // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [telefone, errorMessage]);

	return (
		<div className='container-erro'>
			<img className='logo-erro' src={C6} alt='C6' />
			<div className='container-erro-items'>
				<div className='container-erro-label'>{errorMessage}</div>
				<p style={{ color: '#ccc' }}>Entre em contato conosco</p>
				<p style={{ color: '#ccc' }}>{telefone}</p>
			</div>
		</div>
	);
}
