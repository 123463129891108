import React from 'react';
import c6 from '../../imgs/logo_C6.svg';
import Success from '../../imgs/success.png';

import './telaDesconhece.css';

export default function TelaDesconhece() {
	return (
		<div className='container-tela-desconhece'>
			<img className='tela-desconhece-logo' src={c6} alt='C6' />
      <div className='tela-desconhece-success'>
        <img src={Success} alt='success' />
      </div>
			<div className='tela-desconhece-items'>
				<div className='tela-desconhece-text'>Obrigado pela informação!</div>
			</div>
		</div>
	);
}
