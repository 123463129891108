const initial_state = {
	nome: '',
	cpfCnpj: '',
	nomecompleto: '',
	telefone: '',
	tipo: '',
	digitos: ''
};

const usuario = (state = initial_state, action) => {
	switch (action.type) {
		case 'SET_USUARIO':
			const usuario = action.payload;
			return {
				...state,
				tipo: usuario.tipo,
				nome: usuario.nome,
				cpfCnpj: usuario.cpfCnpj,
				nomecompleto: usuario.nomecompleto,
				telefone: usuario.telefone
			};
		case 'SET_TIPO':
			return { ...state, tipo: action.payload };
		case 'SET_DIGITOS':
			return { ...state, digitos: action.payload };
		default:
			return state;
	}
};

export default usuario;
