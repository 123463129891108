import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import Warning from '../../imgs/warning.svg';

import './errorModal.css'

export default function ErrorModal() {
  
	const dispatch = useDispatch()
	const { errorMessage } = useSelector(state => state.estadoReducer)

	return (
		<div className='container-error-modal' onClick={() => dispatch({ type: 'SET_ERROR_MODAL'})}>
			<div className='wrap-error-modal'>
				<div className='error-modal-image'>
					<img src={Warning} alt='warning' />
				</div>
				<div className='error-modal-message'>
					{errorMessage}
				</div>
				<div>
					<button	className='error-modal-button'>
						Ok
					</button>
				</div>
			</div>
		</div>
	);
}
