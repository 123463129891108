import React, { useState } from 'react';
import { useSelector } from 'react-redux'
import HistoricoService from '../../services/historico'

import './pesquisaSatisfacao.css';
import Close from '../../imgs/close.svg';

const PesquisaSatisfacao = props => {
	const [rate, setRate] = useState(0);
    const [buttonEnabled, setButtonEnabled] = useState(false);
	const usuario = useSelector(state => state.usuarioReducer)
	const boleto = useSelector(state => state.boletoReducer)
	const estado = useSelector(state => state.estadoReducer)
	const historicoService = new HistoricoService(estado)


    const handleStarClick = () => {
		setRate(rate);
        setButtonEnabled(true);
	}
	
	const sendRate = () => {
		historicoService.saveAvaliacao(estado.token, usuario, boleto, rate)
		props.Close();
	}

	return (
		<div className='container-pesquisa-satisfacao'>
			<div className='div-pesquisa'>
				<div className='div-close'>
					<img className='close' src={Close} onClick={props.Close} alt='close' />
				</div>
				<div className="div-pesquisa-text">
					<span>
						Como foi sua experiência com o boleto digital?
					</span>
					<span>
						Selecione as estrelas para dar a sua opinião:
					</span>
				</div>
				<div>
					<div className='rate'>
						<input type="radio" id="star5" name="rate" value="5" /><label htmlFor='star5' title='text' onClick={() => handleStarClick(5)}>5 stars</label>
						<input type="radio" id="star4" name="rate" value="4" /><label htmlFor='star4' title='text' onClick={() => handleStarClick(4)}>4 stars</label>
						<input type="radio" id="star3" name="rate" value="3" /><label htmlFor='star3' title='text' onClick={() => handleStarClick(3)}>3 stars</label>
						<input type="radio" id="star2" name="rate" value="2" /><label htmlFor='star2' title='text' onClick={() => handleStarClick(2)}>2 stars</label>
						<input type="radio" id="star1" name="rate" value="1" /><label htmlFor='star1' title='text' onClick={() => handleStarClick(1)}>1 stars</label>
					</div>
				</div>
				<div className="div-pesquisa-button">
                	<button disabled={!buttonEnabled} onClick={sendRate}>CONFIRMAR</button>
				</div>
			</div>
		</div>
	);
};

export default PesquisaSatisfacao;