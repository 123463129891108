const initial_state = {
	loadingPage: true,
	confirmaCpf: false,
	telaInicial: false,
	gerarBoleto: false,
	boletoGerado: false,
	visualizarPDF: false,
	errorModal: false,
	error: false,
	telaDesconhece: false,
	errorMessage: '',
	errorTitle: '',
	id: '',
	version: '',
	id_tel: '',
	token: '',
	numParcela: 1
};

const estado = (state = initial_state, action) => {
	switch (action.type) {
		case 'SET_LOADING_PAGE':
			return { ...state, loadingPage: action.payload };
		case 'SET_CONFIRMA_CPF':
			return { ...state, confirmaCpf: action.payload };
		case 'SET_TELA_INICIAL':
			return { ...state, telaInicial: action.payload };
		case 'SET_GERAR_BOLETO':
			return { ...state, gerarBoleto: !state.gerarBoleto };
		case 'SET_BOLETO_GERADO':
			return { ...state, boletoGerado: !state.boletoGerado };
		case 'SET_VISUALIZAR_PDF':
			return { ...state, visualizarPDF: !state.visualizarPDF };
		case 'SET_TELA_DESCONHECE':
			return { ...state, telaDesconhece: !state.telaDesconhece };
		case 'SET_ERROR':
			const error = action.payload;
			return { ...state, error: !state.error, errorMessage: error.errorMessage, errorTitle: error.errorTitle };
		case 'SET_URL':
			const url = action.payload;
			return { ...state, id: url[0], version: url[1], id_tel: url[2] };
		case 'SET_TOKEN':
			return { ...state, token: action.payload };
		case 'SET_ERROR_MODAL':
			if (action.payload) return { ...state, errorModal: !state.errorModal, errorMessage: action.payload };
			else return { ...state, errorModal: !state.errorModal };
		default:
			return state;
	}
};

export default estado;
