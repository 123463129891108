import BoletoService from '../services/boleto';
import moment from 'moment';
import swal from 'sweetalert2';
import ReactGA from 'react-ga';

export default class BoletoActions {
	constructor(dispatch, estado, boleto) {
		this.dispatch = dispatch;
		this.boletoService = new BoletoService(estado);
		this.estado = estado;
		this.boleto = boleto;
	}

	getDadosBoleto = token => {
		this.dispatch({ type: 'SET_LOADING_PAGE', payload: true });
		this.dispatch({ type: 'SET_CONFIRMA_CPF', payload: false });
		this.boletoService.getDadosBoleto(token, data => {
			this.montaTelaDadosBoleto(data, token);
		});
	};

	getDadosVencimento = vencimento => {
		this.dispatch({ type: 'SET_LOADING_PAGE', payload: true });
		this.boletoService.getDadosVencimento(this.estado.token, vencimento, data => {
			data.isGetVencimento = true;
			this.montaTelaDadosBoleto(data);
		});
	};

	emitirBoleto = async () => {
		ReactGA.event({
			category: 'Click',
			action: 'Gerar Boleto'
		});
		this.dispatch({ type: 'SET_LOADING_PAGE', payload: true });
		this.boletoService.gerarBoleto(this.estado.token, this.boleto, data => {
			if (data.success) {
				this.boleto = {
					...this.boleto,
					debitoCorr: data.result.valor,
					aVista: data.result.valor,
					linhaDig: data.result.linhaDig,
					linkPDF: data.result.linkPDF,
					mensagemacao: data.result.success ? this.boleto.mensagemacao : this.boleto.mensagemacaogerado
				};
				if (!this.boleto.linhaDig) {
					swal.fire({
						type: 'warning',
						title: 'Aviso!',
						text: 'Seu boleto estará disponivel em até 10 minutos, para isso acesse novamente',
						confirmButtonColor: '#FAB800',
						allowOutsideClick: false
					});
					setTimeout(() => {
						this.dispatch({ type: 'SET_CONFIRMA_CPF', payload: true });
					}, 10000)
				} else {
					this.dispatch({ type: 'SET_BOLETO', payload: this.boleto });
					this.dispatch({ type: 'SET_BOLETO_GERADO' });
				}
			} else {
				this.dispatch({
					type: 'SET_ERROR',
					payload: { errorMessage: data.message ? data.message : 'Erro ao gerar boleto!' }
				});
				this.dispatch({ type: 'SET_LOADING_PAGE', payload: false });
			}
		});
	};

	montaTelaDadosBoleto = (data, token) => {
		if (data.success) {
			const documento = {
				idCarteira: data.result.idCarteira,
				isSMS: data.result.isSMS,
				isSMSComBoleto: false,
				gerado: data.result.gerado,
				debitoCorr:
					this.boleto.numParcela === 1
						? data.result.valorCorrigido
						: !data.result.gerado
						? data.result.parcelas.filter(parcela => parcela.numero === this.boleto.numParcela)[0]
								.valorCorrigido
						: '',
				aVista: data.result.valorAVista,
				valor1Parcela: data.result.valor1Parcela,
				beneficiario: data.result.beneficiario,
				nome: data.result.nome,
				labelContrato: data.result.labelContrato,
				NContrato: data.result.numContrato,
				cpfCnpj: data.result.cpfCnpj,
				tipo: data.result.tipo,
				parcelas: data.result.parcelas || [],
				vencimentos: data.result.vencimentos,
				vencimento: data.result.vencimento || [],
				mensagem: data.result.mensagem,
				mensagemacao: data.result.mensagemacao,
				mensagemacaogerado: data.result.mensagemacaogerado,
				textParcela: !data.isGetVencimento ? data.result.textParcela : undefined,
				qtdParcelasAtraso: data.result.qtdParcelasAtraso,
				telRetorno: data.result.telRetorno,
				permiteReemissao: data.result.permiteReemissao,
				primVencOriginal: data.result.primVencOriginal,
				detalhes: data.result.detalhes,
				possuiTermos: data.result.possuiTermos,
				parcelasGeradas: data.result.parcelasGeradas
			};
			if (data.result.gerado) {
				this.boletoService
					.reemissaoBoleto(documento, token)
					.then(data => {
						if (data.success) {
							documento.debitoCorr = data.result.valor;
							documento.aVista = data.result.valor;
							documento.linhaDig = data.result.linhaDig;
							documento.linkPDF = data.result.linkPDF;
							documento.vencimento = moment(data.result.vencimento, 'YYYY-MM-DD').format('DD/MM/YYYY');
							documento.mensagem = data.result.mensagem;
							documento.mensagemacao = data.result.mensagemacao;
							this.dispatch({ type: 'SET_BOLETO', payload: documento });
							this.dispatch({ type: 'SET_BOLETO_GERADO', payload: true });
						} else {
							this.dispatch({
								type: 'SET_ERROR',
								payload: {
									errorMessage: data.mensagem,
									errorTitle: 'Não conseguimos gerar seu boleto :('
								}
							});
						}
					})
					.catch(err => {
						this.dispatch({
							type: 'SET_ERROR',
							payload: {
								errorMessage: err.error.mensagem,
								errorTitle: 'Não conseguimos gerar seu boleto :('
							}
						});
					});
			} else {
				this.dispatch({ type: 'SET_BOLETO', payload: documento });
				this.dispatch({ type: 'SET_TELA_INICIAL', payload: true });
			}
		} else {
			this.dispatch({
				type: 'SET_ERROR',
				payload: {
					errorMessage: data.mensagem,
					errorTitle: 'Não conseguimos gerar seu boleto :('
				}
			});
		}
	};
}
